"use client"

import { Error } from "../components/error/Error"

/**
 * This file is a last resort error handler for catastrophic errors that
 * happen in the app. It's not localized because locale information is not
 * available when this error boundary is triggered.
 */
export default function GlobalError(props: { error: Error }) {
  return (
    <html lang="en">
      <head />
      <body>
        <Error
          title="Daybridge couldn't start"
          message="Sorry, but we are having trouble loading Daybridge at the moment."
          showDetailsText="Show error details"
          copyErrorDetailsText="Copy error details"
          copiedText="Copied"
          tryAgainText="Try again"
          reloadAppText="Reload the app"
          {...props}
        />
      </body>
    </html>
  )
}
